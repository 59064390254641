@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Bebas-Neue";
    src: url("../public/fonts/BebasNeue-Regular.ttf");
}

@font-face {
    font-family: "Yeseva-One";
    src: url("../public/fonts/YesevaOne-Regular.ttf");
}